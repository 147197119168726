import "./style.css";

const Socials = () => {
    return (
        <div className="socials">
            <a href="https://www.linkedin.com/in/ruben-kober/">
                <i class="bi bi-linkedin"></i>
            </a>
            <a href="mailto:portfolio.gyroo@passinbox.com">
                <i class="bi bi-envelope-paper-heart-fill"></i>
            </a>
        </div>
    );
};

export default Socials;
