import Main from "../../atoms/Main";
import Header from "../../molecules/Header";
import Page from "../../templates/Page";
import "./style.css"

const AboutMePage = () => {
    const age = () => {
        const birthdate = new Date("03.05.2003");
        const today = new Date();
        const age =
            today.getFullYear() -
            birthdate.getFullYear() -
            (today.getMonth() < birthdate.getMonth() ||
                (today.getMonth() === birthdate.getMonth() &&
                    today.getDate() < birthdate.getDate()));
        return age;
    };

    return (
        <Page>
            <Header
                variation="smaller"
                subtitle="Wer bin ich?"
                title="Über mich"
            />
            <Main>
                <p>
                    Hi! Ich bin Ruben Kober aber du kannst mich gerne Ruby
                    nennen. Ich bin {age()} Jahre alt und lebe in der nähe von
                    Bern.
                </p>
                <p>
                    Nachdem Minecraft meine Passion für das Programmieren weckte
                    gab es für mich keinen anderen Weg als eine Berufsausbildung
                    zum Informatiker EFZ.
                </p>
                <p>
                    Aktuell arbeite ich als Ausbildner für
                    Applikationsentwickler bei der Noser Young AG und darf so
                    die Informatiker von morgen unterstützen und mein Wissen weitergeben als auch festigen.
                </p>
                <p>
                    In meiner Freizeit koche und reise ich gerne oder bin auf der Suche nach der schönsten Landstrasse bzw. dem schönsten Pass.
                </p>
            </Main>
        </Page>
    );
};

export default AboutMePage;
