import Subtitle from "../../atoms/Subtitle";
import Title from "../../atoms/Title";
import "./style.css";

const Header = ({title, subtitle, variation="normal"}) => {
    return (
        <div className={"header " + variation}>
            <Subtitle>{subtitle}</Subtitle>
            <Title>{title}</Title>
        </div>
    );
};

export default Header;
