import Pill from "../../atoms/Pill";
import "./style.css";

const ListEntry= ({ duration, company, position, description, skills }) => {
    return (
        <div className="list-entry">
            <span className="list-entry-subtitle">
                {duration} <span style={{ color: "#ac0010" }}>|</span> {company}
            </span>
            <span className="list-entry-title">{position}</span>
            <p className="list-entry-description">{description}</p>
            {skills.map((s) => (
                <Pill skill={s} />
            ))}
        </div>
    );
};

export default ListEntry;
