import { useLocation } from "react-router-dom";
import "./style.css";

const NavigationLink = ({text, link}) => {
    const pathname = useLocation().pathname;

    return (
        <a className={"navigation-link " + (pathname === link && "active")} href={link}>
            {text}
        </a>
    );
};

export default NavigationLink;
