import Socials from "../../atoms/Socials";
import Menu from "../../molecules/Menu";
import "./style.css";

const Page = ({children}) => {
    return (
        <div>
            <div className="row middle-xs justify-content-between page">
                <div className="col">
                  {children}
                </div>
                <div className="col text-end">
                    <Menu />
                </div>
            </div>
        </div>
    );
};

export default Page;
