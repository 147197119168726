import Socials from "../../atoms/Socials";
import Subtitle from "../../atoms/Subtitle";
import Title from "../../atoms/Title";
import Header from "../../molecules/Header";
import Page from "../../templates/Page";
import "./style.css";

const HomePage = () => {
    return (
        <Page>
            <Header title="Ruby" subtitle="Hi! Ich bin" />
            <Socials/>
        </Page>
    );
};

export default HomePage;
