import JobPosition from "../../molecules/ListEntry";
import Main from "../../atoms/Main";
import Header from "../../molecules/Header";
import Page from "../../templates/Page";

const ExperiencePage = () => {
    return (
        <Page>
            <Header
                variation="smaller"
                subtitle="Übung macht den Meister"
                title="Erfahrung"
            />
            <Main>
                <JobPosition
                    duration="08.2023 - Aktuell"
                    position="Ausbildner Applikationsentwicklung"
                    company="Noser Young AG"
                    description="Diese Position erlaubt es mir Lernende während Ihren ersten Schritten in die Berufswelt zu begleiten und zu fördern. So kann ich bereits erste Führungserfahrung sammeln und mein vorhandenes und neuangeeignetes Wissen festigen und weitergeben."
                    skills={["Java", "Spring Boot", "Ausbilden", "Management"]}
                />
                <JobPosition
                    duration="08.2021 - 07.2023"
                    position="Lernender Informatiker EFZ Applikationsentwicklung"
                    company="Noser Engineering AG"
                    description="In diesem Rahmen habe ich als Entwickler erfolgreich an mehreren Webprojekten mitgearbeitet und in einem Projekt zur Bilderkennung auch die Projektleitung übernommen."
                    skills={[
                        "REST-API",
                        "CSS",
                        "MSSQL",
                        "ASP.NET MVC",
                        ".NET-Framework",
                        "Python",
                        "C#",
                        "JavaScript",
                    ]}
                />
                <JobPosition
                    duration="08.2019 - 07.2021"
                    position="Lernender Informatiker EFZ Applikationsentwicklung"
                    company="Noser Young AG"
                    description="Während diesem Teil der Ausbildung konnte ich bereits an
                        mehreren Webprojekten als Entwickler mitwirken. Ein
                        Projekt habe ich auch komplett selbstständig geleitet."
                    skills={[
                        "REST-API",
                        "CSS",
                        "MySQL",
                        "Node.js",
                        "PHP",
                        "React.js",
                        "JavaScript",
                    ]}
                />
            </Main>
        </Page>
    );
};

export default ExperiencePage;
