import Link from "../../atoms/Link";
import Main from "../../atoms/Main";
import Header from "../../molecules/Header";
import ListEntry from "../../molecules/ListEntry";
import Page from "../../templates/Page";

const CertificatePage = () => {
    return (
        <Page>
            <Header
                variation="smaller"
                subtitle="Die Lorbeeren meiner Arbeit"
                title="Zertifikate"
            />
            <Main>
                <ListEntry
                    duration="07.2023"
                    company="Schweizerische Eidgenossenschaft"
                    position="Eidgenössisches Fähigkeitszeugnis"
                    description={
                        <>
                            Eidgenössisches Fähigkeitszeugnis für den Beruf
                            Informatiker EFZ Applikationsentwicklung <br />
                            <Link href="https://drive.proton.me/urls/2T0CKCEZRW#eIY0DYkaBYfK">
                                Zertifikat ansehen
                            </Link>
                        </>
                    }
                    skills={[]}
                />
                <ListEntry
                    duration="07.2023"
                    company="Kanton Bern"
                    position="Notenausweis berufliche Grundbildung"
                    description={
                        <>
                            Notenausweis der beruflichen Grundbildung für den Beruf Informatiker EFZ Applikationsentwicklung <br />
                            <Link href="https://drive.proton.me/urls/0BE3SM5RDG#gteoFyjOaih7">
                                Zertifikat ansehen
                            </Link>
                        </>
                    }
                    skills={[]}
                />
                 <ListEntry
                    duration="07.2023"
                    company="Noser Young AG"
                    position="Ausbildungszeugnis"
                    description={
                        <>
                           Ausbildungszeugnis meiner Lehre zum Informatiker EFZ Applikationsentwicklung <br />
                            <Link href="https://drive.proton.me/urls/5DT5JC1YQ8#ZUkpu8HmdNhC">
                                Zertifikat ansehen
                            </Link>
                        </>
                    }
                    skills={[]}
                />
            </Main>
        </Page>
    );
};

export default CertificatePage;
