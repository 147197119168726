import Link from "../../atoms/Link";
import Main from "../../atoms/Main";
import Header from "../../molecules/Header";
import Page from "../../templates/Page";

const NotFoundPage = () => {
    return (
        <Page>
            <Header title="404" subtitle="Not Found" />
            <Main>
                <p>
                    Nicht gefunden wonach du suchst?
                    <br />
                    Schreib mir ein <Link href="mailto:portfolio.gyroo@passinbox.com">Mail</Link> und wir suchen gemeinsam :)
                </p>
            </Main>
        </Page>
    );
};

export default NotFoundPage;
