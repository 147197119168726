import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/Home";
import NotFoundPage from "./components/pages/NotFound";
import ExperiencePage from "./components/pages/Experience";
import EducationPage from "./components/pages/Education";
import CertificatePage from "./components/pages/Certificates";
import AboutMePage from "./components/pages/AboutMe";
import ContactPage from "./components/pages/Contact";
import DisclaimerPage from "./components/pages/Disclaimer";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" Component={HomePage} />
                <Route path="/experience" Component={ExperiencePage} />
                <Route path="/education" Component={EducationPage} />
                <Route path="/certificates" Component={CertificatePage} />
                <Route path="/aboutme" Component={AboutMePage} />
                <Route path="/contact" Component={ContactPage} />
                <Route path="/disclaimer" Component={DisclaimerPage} />
                <Route path="*" Component={NotFoundPage} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
