import Link from "../../atoms/Link";
import Main from "../../atoms/Main";
import Header from "../../molecules/Header";
import Page from "../../templates/Page";

const ContactPage = () => {
    return (
        <Page>
            <Header
                variation="smaller"
                subtitle="Lust zu quatschen?"
                title="Kontakt"
            />
            <Main>
                <p>
                    Habe ich dein Interesse geweckt? Dann freue ich mich von dir
                    zu hören.
                </p>
                <p>
                    Kontaktier mich auf <Link href="https://www.linkedin.com/in/ruben-kober/">Linkedin</Link> oder per <Link href="mailto:portfolio.gyroo@passinbox.com">Mail</Link>
                </p>
            </Main>
        </Page>
    );
};

export default ContactPage;
