import Link from "../../atoms/Link";
import Main from "../../atoms/Main";
import Header from "../../molecules/Header";
import ListEntry from "../../molecules/ListEntry";
import Page from "../../templates/Page";

const EducationPage = () => {
    return (
        <Page>
            <Header
                variation="smaller"
                title="Ausbildung"
                subtitle="Man kann immer was lernen"
            />
            <Main>
                <ListEntry
                    duration="08.2019 - 07.2023"
                    company="gibb Berufsfachschule Bern"
                    position="Berufliche Grundbildung"
                    description={
                        <>
                            Im Rahmen meiner Ausbildung zum Informatiker EFZ
                            Applikationsentwicklung habe ich die GIBB in Bern
                            besucht. Während dieser Zeit war ich auch im Rent A
                            Stift Programm als Berufsbotschafter tätig und habe
                            Schulklassen meinen Beruf näher gebracht.
                            <br/>
                            <Link href="https://www.srf.ch/audio/regionaljournal-bern-freiburg-wallis/nachfrage-gestiegen-mehr-lernende-sollen-schulklassen-besuchen?id=12088184">
                                Beitrag SRF zum Programm Rent A Stift
                            </Link>
                        </>
                    }
                    skills={["REST-API", "CSS", "MSSQL", "MySQL", "C#"]}
                />
            </Main>
        </Page>
    );
};

export default EducationPage;
