import Link from "../../atoms/Link";
import NavigationLink from "../../atoms/NavigationLink";
import "./style.css";

const Menu = () => {
    return (
        <>
            <ul className="menu">
                <li>
                    <NavigationLink text="Home" link="/" />
                </li>
                <li>
                    <NavigationLink text="Erfahrung" link="/experience" />
                </li>
                <li>
                    <NavigationLink text="Ausbildung" link="/education" />
                </li>
                <li>
                    <NavigationLink text="Zertifikate" link="/certificates" />
                </li>
                <li>
                    <NavigationLink text="Über mich" link="/aboutme" />
                </li>
                <li>
                    <NavigationLink text="Kontakt" link="/contact" />
                </li>
            </ul>
            <div className="disclaimer">
                <Link href="/disclaimer">Impressum</Link>
            </div>
        </>
    );
};

export default Menu;
